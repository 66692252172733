import React from 'react';

import { withAPI } from 'components';
import { Events } from 'api';
import { eventDetailPropTypes } from 'helpers/proptypes';

const InvitationEventTitle = (props) => {
  // TODO: Change this to react router link and ensure it works as expected
  const registrationListUrl = window.URLS['pd:event_registration_list'](props.data.id);
  return (
    <div>
      <h1>
        {props.data.title}
        <a href={registrationListUrl} className="btn btn-default pull-right">
          <i className="fa fa-users icon-space-r" />
          Registration List
        </a>
      </h1>
    </div>
  );
};

InvitationEventTitle.propTypes = {
  data: eventDetailPropTypes,
};

export const InvitationEventTitleUnwrapped = InvitationEventTitle;

export default withAPI((props) => Events.detail(props.eventId))(InvitationEventTitle);
