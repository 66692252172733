import React from 'react';
import PropTypes from 'prop-types';
import Selectize from 'helpers/selectize';
import { User } from 'api';

const defaultProps = {
  disabled: false,
  multiple: true,
  valueField: 'pk',
  openOnFocus: true,
  closeAfterSelect: true,
  labelField: 'name',
  searchField: 'name',
  persist: false,
  plugins: ['remove_button'],
  placeholder: 'Search for attendees by name',
  className: 'form-control events__search',
  create: false,

  render: {
    item: (item, escape) => {
      return (
        '<div>' +
        `<span class="event-search__term-type">User:</span>` +
        (item.name ? `<span class="event-search__term-name">${escape(item.name)}</span>` : '') +
        '</div>'
      );
    },
    option: (item, escape) => {
      return (
        '<div>' +
        `<span class="event-search__term-type">User:</span>` +
        (item.name ? `<span class="event-search__term-name">${escape(item.name)}</span>` : '') +
        '</div>'
      );
    },
  },
};

export default class InvitationSelectize extends React.Component {
  static propTypes = {
    invitedUsers: PropTypes.arrayOf(PropTypes.number),
    setSelectizeRef: PropTypes.func.isRequired,
  };

  state = {
    userChoices: [],
    filteredUserChoices: [],
  };

  componentDidMount() {
    User.permissionList('can-register').then((res) => {
      const users = res.filter((user) => this.props.invitedUsers.indexOf(user.pk) < 0);
      const filteredUserChoices = users.map((user) => ({ pk: user.pk, name: user.name }));
      this.setState({ filteredUserChoices, userChoices: res });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.invitedUsers !== nextProps.invitedUsers) {
      const filteredUserChoices = this.state.userChoices.filter(
        (userChoice) => nextProps.invitedUsers.indexOf(userChoice.pk) < 0
      );
      this.setState({ filteredUserChoices });
    }
  }

  render() {
    return (
      <Selectize
        {...defaultProps}
        options={this.state.filteredUserChoices}
        refSelectize={this.props.setSelectizeRef}
      />
    );
  }
}
