import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import Invitations from './Invitations';
import InvitationEventTitle from './InvitationEventTitle';

export default class InvitationsPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
  };

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="white-soft-box2 white-soft-box2--no-animate">
            <InvitationEventTitle eventId={this.props.match.params.eventId} />
            <Invitations eventId={this.props.match.params.eventId} />
          </div>
        </div>
      </div>
    );
  }
}
