import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InviteStatus = ({ status, ...props }) => {
  const classes = classNames('label', {
    'label-info': status === undefined || status === null,
    'label-danger': status === false,
    'label-success': status === true,
  });
  const labelText = status === true ? 'Accepted' : status === false ? 'Declined' : 'Pending';
  return <span className={classes}>{labelText}</span>;
};

InviteStatus.propTypes = {
  status: PropTypes.bool,
};

export default InviteStatus;
