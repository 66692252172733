import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { requestEventsDetail } from 'store/actions/events/detail';
import { EventInvitations } from 'api';
import { withAPI } from 'components';
import InvitationSelectize from './InvitationSelectize';
import InviteStatus from './components/InviteStatus';

class Invitations extends React.Component {
  static propTypes = {
    eventId: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    fetchEventDetail: PropTypes.func.isRequired,
  };

  state = {
    invitations: this.props.data || [],
  };

  componentDidMount() {
    this.props.fetchEventDetail(this.props.eventId);
  }

  sendInvites = () => {
    const users = this.selectizeRef.getValue();
    const { eventId } = this.props;
    users.forEach((user) => {
      const data = { user, event: eventId };
      EventInvitations.post(data).then((res) => {
        const newInvite = { ...res };
        const invitations = this.state.invitations.concat(newInvite);
        this.setState({ invitations });
      });
    });
    this.selectizeRef.clear();
  };

  revokeInvite = (inviteId) => {
    const invitations = this.state.invitations.filter((invite) => invite.pk !== inviteId);
    EventInvitations.delete(inviteId).then(() => this.setState({ invitations }));
  };

  setSelectizeRef = (selectize) => {
    this.selectizeRef = selectize;
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ invitations: nextProps.data });
    }
  }

  render() {
    const invitedUsers = this.state.invitations.map((invite) => invite.user);
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">
            <div className="flex-single-row-container flex-nowrap">
              <InvitationSelectize
                setSelectizeRef={this.setSelectizeRef}
                invitedUsers={invitedUsers}
              />
              <button
                className="btn btn-info icon-space-l"
                type="button"
                onClick={this.sendInvites}
              >
                <i className="fa fa-paper-plane icon-space-r" />
                Send Invites
              </button>
            </div>
          </div>
        </div>
        <div className="row mt2">
          <div className="col-xs-12">
            <div className="table-responsive">
              <table className="table">
                <thead className="invitations-table">
                  <tr>
                    <th>Sent</th>
                    <th>Sent By</th>
                    <th>Invitee</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="invitations-table">
                  {this.state.invitations.map((invite) => {
                    const sentDate = moment(invite.created);
                    return (
                      <tr key={invite.pk}>
                        <td>{sentDate.format('ddd, MMMM Do YYYY, h:mm a')}</td>
                        <td>{invite.author_name}</td>
                        <td>{invite.user_name}</td>
                        <td>
                          <InviteStatus status={invite.accepted} />
                        </td>
                        <td>
                          {invite.accepted === null ? (
                            <button
                              className="btn-xs btn-outline-danger"
                              type="button"
                              onClick={() => this.revokeInvite(invite.pk)}
                            >
                              <i className="fa fa-times icon-space-r" /> Revoke
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const InvitationsUnwrapped = Invitations;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEventDetail: (eventId) => dispatch(requestEventsDetail(eventId)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withAPI((props) => EventInvitations.get(props.eventId))(Invitations));
